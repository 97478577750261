
import { Component } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { ElForm } from 'element-ui/types/form'
import ZButton from '@/components/base/z-button.vue'
import printJS from 'print-js'
@Component({
	components: { ZButton }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private isLoading = false

	private searchForm: any = {
		code: ''
	}

	private validateForm = {
		code: [{ required: true, message: '请扫描包裹号', trigger: 'blur' }]
	}
	async searchPackage() {
		this.isLoading = true
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		const { code } = this.searchForm
		const { data } = await this.$axios
			.get(`/v1/jobline/client/print?code=${code}`, {})
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => (this.isLoading = false))
		this.$message.success('正在打印请稍后...')
		printJS(data[0])
	}
}
